<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">合同中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">合同复核</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="合同编号" class="searchboxItem ci-full">
              <span class="itemLabel">合同编号:</span>
              <el-input clearable placeholder="请输入合同编号" v-model="contractCode" size="small">
              </el-input>
            </div>
            <div title="合同类型" class="searchboxItem ci-full">
              <span class="itemLabel">合同类型:</span>
              <el-select size="small" v-model="contractType" clearable placeholder="请选择合同类型">
                <el-option v-for="item in contractTypeList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="合同用途" class="searchboxItem ci-full">
              <span class="itemLabel">合同用途:</span>
              <el-select size="small" v-model="contractPurpose" clearable placeholder="请选择合同用途">
                <el-option v-for="item in contractPurposeList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="签订机构" class="searchboxItem ci-full">
              <span class="itemLabel">签订机构:</span>
              <el-select size="small" v-model="signCompId" remote :remote-method="getCompanyList" filterable clearable
                placeholder="请至少输入两个字搜索">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="合同状态" class="searchboxItem ci-full">
              <span class="itemLabel">合同状态:</span>
              <el-select size="small" v-model="settleState" clearable placeholder="请选择合同状态">
                <el-option v-for="item in settleStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="合同形式" class="searchboxItem ci-full">
              <span class="itemLabel">合同形式:</span>
              <el-select size="small" v-model="contractForm" clearable placeholder="请选择合同形式">
                <el-option v-for="item in contractFormList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="代理商" class="searchboxItem ci-full">
              <span class="itemLabel">代理商:</span>
              <el-select size="small" v-model="agencyId" remote :remote-method="getAgencyList" filterable clearable
                placeholder="请至少输入两个字搜索">
                <el-option v-for="item in AgencyList" :key="item.agencyId" :label="item.agencyName"
                  :value="item.agencyId"></el-option>
              </el-select>
            </div>
            <div title="报备日期" class="searchboxItem ci-full">
              <span class="itemLabel">报备日期:</span>
              <el-date-picker clearable size="small" v-model="filingDate" type="date" placeholder="请选择报备日期"
                format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="经办人" class="searchboxItem ci-full">
              <span class="itemLabel">经办人:</span>
              <el-select clearable placeholder="请选择经办人" size="small" v-model="adminId">
                <el-option v-for="item in managerList" :key="item.adminId" :label="item.fullname" :value="item.adminId">
                </el-option>
              </el-select>
            </div>
            <div title="审批状态" class="searchboxItem ci-full">
              <span class="itemLabel">审批状态:</span>
              <el-select size="small" v-model="auditType" clearable placeholder="请选择审批状态">
                <el-option v-for="item in ApprovalStatusList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader clearable filterable v-model="areaId" :options="areatreeList" :props="propsarea"
                size="small"></el-cascader>
            </div>
            <div title="客户性质" class="searchboxItem ci-full">
              <span class="itemLabel">客户性质:</span>
              <el-select clearable placeholder="请选择客户性质" size="small" v-model="customerNature">
                <el-option v-for="item in customerNatureList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="实际培训机构" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">实际培训机构:</span>
              <el-select size="small" v-model="actualCompId" remote :remote-method="getactualList" filterable clearable
                placeholder="请至少输入两个字搜索">
                <el-option v-for="item in CompanyactualList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="签订日期" class="searchboxItem ci-full">
              <span class="itemLabel">签订日期:</span>
              <el-date-picker clearable size="small" v-model="signingTime" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="exportReview()">导出</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="合同编号" align="left" show-overflow-tooltip prop="contractCode" min-width="180px" />
              <el-table-column label="合同类型" align="left" show-overflow-tooltip prop="contractType" width="80px">
                <template slot-scope="scope">
                  {{
                    scope.row.contractType == "10"
                      ? "收款合同"
                      : scope.row.contractType == "20"
                        ? "付款合同"
                        : scope.row.contractType == "30"
                          ? "备案合同"
                          : "代理合同"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="合同形式" align="left" show-overflow-tooltip prop="contractForm" min-width="100px">
                <template slot-scope="scope">
                  {{ $setDictionary("CONTRACT_FORM", scope.row.contractForm) }}
                </template>
              </el-table-column>
              <el-table-column label="合同用途" align="left" show-overflow-tooltip prop="contractPurpose" min-width="100px">
                <template slot-scope="scope">
                  {{ $setDictionary("CONTRACT_PURPOSE", scope.row.contractPurpose) }}
                </template>
              </el-table-column>
              <el-table-column label="客户性质" align="left" show-overflow-tooltip min-width="100px">
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "CUSTOMER_NATURE",
                      scope.row.customerNature
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column label="实际培训机构" align="left" show-overflow-tooltip prop="actualCompName"
                min-width="220px" />
              <el-table-column label="签订机构" align="left" show-overflow-tooltip prop="signCompName" min-width="220px" />
              <el-table-column label="代理商" align="left" show-overflow-tooltip prop="agencyName" min-width="220px" />
              <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaNamePath" min-width="220px" />
              <el-table-column label="签订日期" align="left" show-overflow-tooltip prop="signDate" min-width="100px">
                <template slot-scope="scope">
                  {{ scope.row.signDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="有效期" align="left" show-overflow-tooltip prop="compName" min-width="180px">
                <template slot-scope="scope">
                  {{ scope.row.startDate.replaceAll("/", "-") }} ~
                  {{ scope.row.endDate.replaceAll("/", "-") }}
                </template>
              </el-table-column>
              <el-table-column label="结算标准" align="left" show-overflow-tooltip prop="settleStandardType"
                min-width="140px">
                <template slot-scope="scope">
                  <span v-if="scope.row.contractType != '20'">
                    {{
                      $setDictionary(
                        "SETTLEMENT_STANDARD",
                        scope.row.settleStandardType
                      )
                    }}
                  </span>
                  <span v-else>
                    {{
                      $setDictionary(
                        "SETTLEMENT_STANDARD_PAYMENT",
                        scope.row.settleStandardType
                      )
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="对接业务员" align="left" show-overflow-tooltip prop="saleName" min-width="100px" />
              <el-table-column label="最新负责人" align="left" show-overflow-tooltip prop="turnOverSaleName"
                min-width="100px" />
              <el-table-column label="审批状态" align="left" show-overflow-tooltip prop="auditType" min-width="120px">
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "FD_CONTRACT_AUDIT_TYPE",
                      scope.row.auditType
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column label="经办人" align="left" show-overflow-tooltip prop="operator" min-width="150px">
              </el-table-column>
              <el-table-column label="备注" align="left" show-overflow-tooltip prop="remark" min-width="150px">
              </el-table-column>
              <el-table-column label="报备日期" align="left" show-overflow-tooltip prop="createTime" min-width="150px">
                <template slot-scope="scope">
                  {{ scope.row.filingDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="财务备注" align="left" show-overflow-tooltip prop="financialRemark" min-width="150px">
              </el-table-column>
              <el-table-column label="操作" align="center" width="150px" fixed="right">
                <div slot-scope="scope">
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="
                    editModel(
                      scope.row.contractId,
                      scope.row.isApproval,
                      scope.row.auditType
                    )
                    ">{{
                      scope.row.auditType != "15" ? "查看详情" : "财务复核"
                    }}</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="invalidContract(scope.row.contractId)">作废</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/contractManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      contractCode: "", //合同编号
      contractType: "", //合同类型
      contractForm: "", //合同形式
      contractPurpose: "", //合同用途
      signingTime: "", //签订日期
      auditType: "", //审批状态
      filingDate: "", //报备日期
      adminId: '',
      contractsealState: "",
      CompanyList: [],
      CompanyactualList: [],
      signCompId: "",
      actualCompId: "",
      agencyId: "", //代理商
      areaId: "", //行政区划
      areatreeList: [], // 行政区划
      customerNature: "", //客户性质
      customerNatureList: [], //客户性质list
      // 行政区划 - 数据格式
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      contractTypeList: [
        {
          label: "收款合同",
          value: "10",
        },
        {
          label: "付款合同",
          value: "20",
        },
        {
          label: "备案合同",
          value: "30",
        },
        {
          label: "代理合同",
          value: "40",
        },
      ],
      //合同状态
      settleStateList: [
        {
          label: "未开始",
          value: "10",
        },
        {
          label: "有效",
          value: "20",
        },
        {
          label: "已过期",
          value: "30",
        },
      ],
      settleState: "",
      managerList: [], //经办人数据
      AgencyList: [],
      ApprovalStatusList: [],
      contractFormList: [],// 合同形式
      contractPurposeList: [],// 合同用途
    };
  },
  watch: {},
  created() { },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getareatree();
    this.getCodeValue();
    this.getTableHeight();
    this.getManagerList();
    this.getcontractFormList();
  },
  methods: {
    // 获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 获取合同形式、合同用途字典
    getcontractFormList() {
      const list1 = this.$setDictionary("CONTRACT_FORM", "list");
      let contractFormList = [];
      for (const key in list1) {
        contractFormList.push({
          value: key,
          label: list1[key]
        });
      }
      this.contractFormList = contractFormList;
      const list2 = this.$setDictionary("CONTRACT_PURPOSE", "list");
      let contractPurposeList = [];
      for (const key in list2) {
        contractPurposeList.push({
          value: key,
          label: list2[key]
        });
      }
      this.contractPurposeList = contractPurposeList;
    },
    // 获取经办人信息
    getManagerList() {
      this.managerList = []
      this.$post("/biz/new/bill/contract/managerList", { userName: '' })
        .then((res) => {
          if (res.status == 0) {
            this.managerList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    /* 实际培训机构名称 */
    getactualList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyactualList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyactualList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.contractCode) {
        params.contractCode = this.contractCode;
      }
      if (this.contractType) {
        params.contractType = this.contractType;
      }
      if (this.contractForm) {
        params.contractForm = this.contractForm;
      }
      if (this.contractPurpose) {
        params.contractPurpose = this.contractPurpose;
      }
      if (this.settleState) {
        params.settleState = this.settleState;
      }
      if (this.adminId) {
        params.createId = this.adminId;
      }
      if (this.signCompId) {
        params.signCompId = this.signCompId;
      }
      if (this.actualCompId) {
        params.actualCompId = this.actualCompId;
      }
      if (this.signingTime) {
        params.signStartDate = this.signingTime[0];
        params.signEndDate = this.signingTime[1];
      }
      if (this.filingDate) {
        params.filingDate = this.filingDate;
      }
      if (this.auditType) {
        params.auditType = this.auditType;
      }
      if (this.agencyId) {
        params.agencyId = this.agencyId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.customerNature) {
        params.customerNature = this.customerNature;
      }
      this.doFetch({
        url: "/biz/new/bill/contract/pageList",
        params,
        pageNum,
      });
    },
    //编辑
    editModel(contractId, isApproval, auditType) {
      this.$router.push({
        path: "/web/financialSettlement/financialReview",
        query: {
          contractId,
          isApproval,
          auditType,
          stu: "look",
        },
      });
    },
    //作废
    invalidContract(contractId) {
      this.doDel({
        url: "/biz/contractAudit/invalid",
        msg: "确定作废合同吗？",
        ps: {
          type: "post",
          data: { contractId },
        },
      });
    },

    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyListPop = [res.data];
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4.5) * 16;
      if (opDom) {
        tHeight -= 85 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    // 获取 - 代理商
    getAgencyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/biz/mercantile/agency/getListByName", {
          agencyName: query,
        })
          .then((res) => {
            if (res.status == 0) {
              this.AgencyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.AgencyList = [];
      }
    },
    getCodeValue() {
      const ApprovalStatus = this.$setDictionary(
        "FD_CONTRACT_AUDIT_TYPE",
        "list"
      );
      for (const key in ApprovalStatus) {
        this.ApprovalStatusList.push({
          value: key,
          label: ApprovalStatus[key],
        });
      }
      const customerNatureList = this.$setDictionary(
        "CUSTOMER_NATURE",
        "list"
      );
      for (const key in customerNatureList) {
        this.customerNatureList.push({
          value: key,
          label: customerNatureList[key],
        });
      }
    },
    //导出
    exportReview() {
      const params = {};
      if (this.contractCode) {
        params.contractCode = this.contractCode;
      }
      if (this.contractType) {
        params.contractType = this.contractType;
      }
      if (this.contractForm) {
        params.contractForm = this.contractForm;
      }
      if (this.contractPurpose) {
        params.contractPurpose = this.contractPurpose;
      }
      if (this.settleState) {
        params.settleState = this.settleState;
      }
      if (this.createId) {
        params.createId = this.createId;
      }
      if (this.signCompId) {
        params.signCompId = this.signCompId;
      }
      if (this.actualCompId) {
        params.actualCompId = this.actualCompId;
      }
      if (this.signingTime) {
        params.signStartDate = this.signingTime[0];
        params.signEndDate = this.signingTime[1];
      }
      if (this.filingDate) {
        params.filingDate = this.filingDate;
      }
      if (this.auditType) {
        params.auditType = this.auditType;
      }
      if (this.agencyId) {
        params.agencyId = this.agencyId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.customerNature) {
        params.customerNature = this.customerNature;
      }
      this.$post("/biz/new/bill/contract/financial/export", params).then(
        (res) => {
          if (res.status == "0") {
            let list = res.data;
            for (let item of list) {
              console.log(item);
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(re.message);
          }
        }
      );
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.query.refresh == "true") {
          this.getData(-1);
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.el-tooltip__popper {
  max-width: 45% !important;
}
</style>
